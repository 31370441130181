/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        return (
          <Container>
            <p>
              Autorem artykułu jest radca prawny <b>Paweł Jurczyński.</b> <br/> W przypadku pytań -  Kancelaria Radcy Prawnego Paweł Jurczyński pozostaje do dyspozycji pod numerem <a style={{boxShadow: 'none'}} href="tel:+534089846">tel. 534 089 846</a> lub mailowo: <a style={{boxShadow: 'none'}} href="mailto:pawel@jurczynskikancelaria.pl">pawel@jurczynskikancelaria.pl</a>
            </p>
          </Container>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
